<script>
	import styles from './Nav.module.css';

	let className = '';
	export { className as class };
</script>

<aside class="{styles.nav} {className}">
	<nav>
		<ul class={styles.items}>
			<slot />
		</ul>
	</nav>
</aside>
<div class="{styles.spacer} {className}"></div>
