<script>
	import { Icon } from '@ctm/icons';
	import { Applications, Home, GridView, Chat } from '@ctm/icons/interface';
	import { Compass as CompassLogo } from '@ctm/icons/products';
	import { Compass } from '@ctm/icons/travel';
	import { base } from '$app/paths';
	import { page } from '$app/stores';
	import { PUBLIC_VERSION } from '$env/static/public';
	import Flex from '$lib/Flex';
	import HeaderBar from '$lib/HeaderBar';
	import Menu from '$lib/Menu';
	import Nav from '$lib/experimental/Nav';
	import Text from '$lib/Text';
	import styles from './+layout.module.css';
	import Flask from './Flask.svg?json';

	/** @type {import('./$types.d.ts').LayoutData} */
	export let data;

	// /** @type {Element | undefined} */
	// let content;

	$: console.debug('versions = %o', data.versions);
	$: console.debug('versionOptions = %o, $page.url.pathname = %o', data.versionOptions, $page.url.pathname);
</script>

<HeaderBar>
	<Icon icon={CompassLogo} slot="logo" />
	<Flex paddingRight={data.versions.length > 1 ? 'md' : 'xl'} valign="middle" slot="right">
		{#if data.versions.length > 1}
			<Menu.Button label="v{PUBLIC_VERSION}" size="md">
				{#each data.versionOptions as option}
					<Menu.Item {...option} checked={option.href === $page.url.pathname} type="radio" />
				{/each}
			</Menu.Button>
		{:else}
			<Text weight="semibold">v{PUBLIC_VERSION}</Text>
		{/if}
	</Flex>
</HeaderBar>
<Flex valign="top">
	<Nav>
		<Nav.Item href="{base}/" icon={Home} label="Home" />
		<Nav.Item href="{base}/foundations" icon={Compass} label="Foundations">
			<Nav.Item href="{base}/foundations/intro" label="Introduction" />
			<Nav.Item href="{base}/foundations/colour" label="Colour" />
			<Nav.Item href="{base}/foundations/elevation" label="Elevation" />
			<Nav.Item href="{base}/foundations/corners" label="Corner Radii" />
			<Nav.Item href="{base}/foundations/grid" label="Grid" />
			<Nav.Item href="{base}/foundations/icons" label="Icons" />
			<Nav.Item href="{base}/foundations/spacing" label="Spacing" />
			<Nav.Item href="{base}/foundations/typography" label="Typography" />
		</Nav.Item>
		<Nav.Item href="{base}/components" icon={Applications} label="Components">
			{#each data.components as component (component.href)}
				<Nav.Item href={component.href} label={component.title}>
					<svelte:fragment slot="tag">
						{#if component.experimental}
							<Icon class={styles['experimental-icon']} icon={Flask} size="sm" title="experimental" />
						{/if}
					</svelte:fragment>
				</Nav.Item>
			{/each}
		</Nav.Item>
		<Nav.Item href="{base}/patterns" icon={GridView} label="Patterns" />
		<Nav.Divider />
		<Nav.Item href="https://travelctm.atlassian.net/CreateIssue.jspa?pid=12561&issuetype=1" icon={Chat} label="Report bug" />
	</Nav>
	<slot />
</Flex>

<style>
	:global(:root) {
		scroll-padding-top: calc(var(--space-lg) + 70px); /* paddingY + HeaderBar height */
	}
</style>
