<script>
	import { Icon } from '@ctm/icons';
	import { ChevronDown, ChevronUp } from '@ctm/icons/directional';
	import { afterNavigate } from '$app/navigation';
	import { base } from '$app/paths';
	import { page } from '$app/stores';
	import styles from './Nav.module.css';

	/** @type {string} */
	export let href;

	/** @type {IconData | undefined} */
	export let icon = undefined;

	/** @type {string} */
	export let label;

	$: hasChildren = !!$$slots.default;

	/**
	 * @param {string} href
	 * @param {URL} currentUrl
	 * @returns {boolean}
	 */
	function isActive(href, currentUrl) {
		if (!href) {
			return false;
		}
		const currentHref = currentUrl.pathname;
		if (href === `${base}/`) {
			return currentHref === href;
		}
		return currentHref.startsWith(href) && [undefined, '/'].includes(currentHref.at(href.length));
	}
	$: active = isActive(href, $page.url);

	let expanded = false;
	afterNavigate(({ to }) => {
		if (to?.url.pathname.startsWith(href)) {
			expanded = true;
		}
	});
</script>

<li class="{styles.item} {hasChildren ? styles['has-children'] : ''}">
	{#if hasChildren}
		<button class={styles.button} aria-pressed={expanded} on:click={() => (expanded = !expanded)}>
			{#if icon}
				<Icon class={styles.icon} {icon} size="md" />
			{/if}
			<span class={styles.label}>{label}</span>
			<slot name="tag" />
			<Icon icon={expanded ? ChevronUp : ChevronDown} size="sm" />
		</button>
	{:else}
		{@const target = href.startsWith('/') ? undefined : '_blank'}
		<a class="{styles.link} {active ? styles.active : ''}" {href} {target}>
			{#if icon}
				<Icon class={styles.icon} {icon} size="md" />
			{/if}
			<span class={styles.label}>{label}</span>
			<slot name="tag" />
		</a>
	{/if}
	{#if hasChildren && expanded}
		<ul class={styles.items}>
			<slot />
		</ul>
	{/if}
</li>
