import { browser, dev } from '$app/environment';
import { base } from '$app/paths';
import { PUBLIC_BRANCH, PUBLIC_VERSION } from '$env/static/public';

/**
 * @typedef Version
 * @prop {string} name
 * @prop {string} branch
 * @prop {string} base
 * @prop {boolean} latest
 */

const defaultVersions = [
	{
		name: `v${PUBLIC_VERSION}`,
		branch: PUBLIC_BRANCH,
		base,
		latest: false,
	},
];

/**
 * @param {Version[]} versions
 * @param {URL} url
 */
function getVersionOptions(versions, url) {
	const currentPath = url.pathname.slice(base.length);
	return versions.map((version) => ({
		label: version.name,
		description: version.branch,
		href: version.base + currentPath,
	}));
}

export async function load({ data, fetch, url }) {
	const versions =
		browser && !dev
			? await fetch('/versions.json')
					.then((response) => {
						if (!response.ok) {
							throw new Error(`request failed with status ${response.status}`);
						}
						return /** @type {Promise<Version[]>} */ (response.json());
					})
					.catch((exception) => {
						if (browser) {
							console.error('failed to fetch versions: %o', exception);
						}
						return defaultVersions;
					})
			: defaultVersions;
	const versionOptions = getVersionOptions(versions, url);
	return {
		...data,
		versions,
		versionOptions,
	};
}
