import NavComponent from './Nav.svelte';
import NavDivider from './NavDivider.svelte';
import NavItem from './NavItem.svelte';

const Nav = Object.assign(NavComponent, {
	Item: NavItem,
	Divider: NavDivider,
});

export default Nav;
